import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import style from "./services.module.css"
import styled from "./page.module.css"
import Navbar from "../components/Navbar"
import Footer from "../components/footer"

const Agile = ({data}) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return ( 
  <>
  <Navbar />
   <header>
      <div className={style.p}>
      <div className={style.heading}>“As a software group manager/agile team leader I want team members to test faster, more effective and on time,so that the Team can deliver high quality Product at the end of each iteration.”</div>
      </div>
   </header>
      <div className={style.width}>
      <Img className={style.img} fluid={data.file.childImageSharp.fluid} alt="img" />
      </div>
      <div className={styled.content} >
      <h2>{frontmatter.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: html }}/>
      </div>
      <Footer />
   
      </>
      
  )
}
export const query = graphql`
query agile {
    markdownRemark(fileAbsolutePath: {regex: "/AgileTesting.md/"}) {
        html
        frontmatter {
          title
        }
      }
      file(name: { eq: "prueba_agile_testing_detail" }, extension: { eq: "png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, pngQuality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `

export default Agile